$breakpoint-alpha: 768px; // adjust to your needs

.rwd-table {
  margin: 1.9rem 0;
  min-width: 100%; // adjust to your needs
  border-collapse: collapse;

  tr {
    // border-top: 1px solid #ddd;
    // border-bottom: 1px solid #ddd;
  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!   
  }

  td {
    display: block;

    &:first-child {
      padding-top: .5em;
    }

    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
      //font-weight: bold;

      // optional stuff to make it look nicer
      //width: 6em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options

      @media (min-width: $breakpoint-alpha) {
        display: none;
        color: $white-color;
      }
    }
  }

  .table-head {
    background: $color-first;
    border: none;

    .right-curve {
      border-bottom-right-radius: $border-radius;
    }

    .left-curve {
      border-bottom-left-radius: $border-radius;
    }
  }

  th,
  td {
    text-align: right;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

  }

  td {
    display: flex;
    justify-content: space-between;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;

    }
  }

  &.empty {
    min-height: 300px;

    @media (min-width: $breakpoint-alpha) {

      min-height: auto;


    }

    tr {
      display: none;

      @media (min-width: $breakpoint-alpha) {
        display: table-row;
        visibility: hidden;


      }
    }

    .table-head {
      visibility: visible;

    }
  }

}


// presentational styling


.rwd-table {
  background: $white-color;
  color: $color-typo-main;
  position: relative;

  @media (min-width: $breakpoint-alpha) {
    color: $color-typo-second;

  }

  border-radius:$border-radius;
  box-shadow: $box-shadow-select;
  overflow: hidden;

  tr {
    font-size: 1.5rem;

    // border-color: lighten(#34495E, 10%);
    &.colored {
      background: $body-bg;
    }
  }

  th,
  td {
    margin: 1.4rem;
    font-weight: 100;
    font-family: 'SF-Regular';
    line-height: 21px;
    font-size: 1.35rem;

    @media (min-width: 365px) {
      font-size: 1.6rem;
    }

    @media (min-width: $breakpoint-alpha) {
      padding: 2.2rem 2rem !important;
      font-family: 'SF-Regular';

    }
  }

  th,
  td:before {
    font-family: 'SF-Regular';
    color: $color-typo-second;

    @media (min-width: $breakpoint-alpha) {
      color: $white-color;

    }
  }
}

.empty-content {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img {

    width: 8.4rem;
    height: 8.4rem;

    @include respond(s) {
      width: 12.8rem;
      height: 12.8rem;
    }

    background: #d8e1e8;
    border-radius: 50%;
    margin-bottom: 1.8rem;

    img {
      width: 3.6em;
      height: 4.1rem;

      @include respond(s) {
        width: 5.6rem;
        height: 6.3rem;
      }
    }
  }

  p {
    font-family: 'SF-Medium';
    font-size: 1.8rem;
    color: $color-typo-second;

    @include respond(m) {
      font-size: 2rem;

    }
  }
}


.english {
  .rwd-table {
    .table-head {

      .right-curve {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: 0;
      }

      .left-curve {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: 0;
      }
    }
  }

  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }
}