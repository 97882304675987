.u-center-text {
    text-align: center !important;
}

.u-margin-bottom-big {
    margin-bottom: 4rem !important;

    @include respond(m) {
        margin-bottom: 8rem !important;
    }
}

.u-margin-bottom-small {
    margin-bottom: 1rem !important;

    @include respond(m) {
        margin-bottom: 1.5rem !important;
    }
}

.u-nocol-margin-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.custom-line {
    border-top: .50rem solid #eff1f4;
}

.cursor-pointer {
    cursor: pointer;
}

.u-margin-bottom-medium {
    margin-bottom: 2.5rem !important;

    @include respond(m) {
        margin-bottom: 4rem !important;
    }
}

.slide-padding {
    padding: .7rem;

    @include respond(s) {
        padding: 2rem;

    }
}

.align-items-center {
    align-items: center !important;

}

.u-margin-top-big {
    margin-top: 4rem !important;

    @include respond(m) {
        margin-top: 8rem !important;
    }
}

.u-margin-top-huge {
    margin-top: 7rem !important;

    @include respond(m) {
        margin-top: 10rem !important;
    }
}

.u-center-left {
    text-align: left;
}

//Counts

.border-fancy {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .3);
    top: 0;
    left: 2rem;

    border-radius: 30% 70% 54% 46% / 30% 30% 70% 70%;
}

.position-relative {
    position: relative;
}

.u-margin-all-childs {
    margin-bottom: 3rem;
}

.shadow-lg {
    box-shadow: 0 2rem 1.5rem -1.5rem rgba(33, 37, 41, .15), 0 0 1.5rem .5rem rgba(38, 37, 41, .05);

}

.shadow-hover {
    &:hover {
        box-shadow: 0 2.25rem 1.5rem -1.5rem rgba(33, 37, 41, .3), 0 0 1.5rem .5rem rgba(33, 37, 41, .05);
        transform: translateY(-.5REM);



        backface-visibility: hidden;
    }

    &:active {
        box-shadow: 0 2rem 1.5rem -1.5rem rgba(33, 37, 41, .15), 0 0 1.5rem .5rem rgba(33, 37, 41, .05);
        transform: translateY(-.1rem);
    }

}

.text-left {
    text-align: left;
}

.font-intial {
    font-size: initial;
}

.text-right {
    text-align: right;
}

.font-0 {
    font-size: 0;
}

.display-in-bl {
    display: inline-block;
}

.mg-btm-3 {
    margin-bottom: 3rem;
}

.mg-btm {
    margin-bottom: 1rem;
}

.mg-top {
    margin-top: 1rem;

    &-big {
        margin-top: 2rem;

    }

    &-bigger {
        margin-top: 2.6rem;

    }

    &-middle {
        margin-top: 1.6rem;

    }
}

.page-body-mobile {

    position: relative;
    margin-top: -13.2rem;
    z-index: 3;

    @include respond(m) {

        margin-top: 0;


    }

}

.top-distance {
    //margin-top: 9rem;
    padding-top: 8.4rem;

    @include respond(m) {
        padding-top: 12rem;

    }
}

.bottom-distance {
    //margin-top: 9rem;
    padding-bottom: 5rem;

    @include respond(m) {
        padding-bottom: 8.6rem;

    }
}

.has-line {
    position: relative;

    display: inline-block;
    z-index: 1;
    padding-right: .8rem;
    padding-left: .8rem;

    &::after {
        background: $color-first;
        content: '';
        height: 1.3rem;
        display: block;
        width: 100%;
        left: 0%;
        right: 0;
        position: absolute;
        bottom: 0rem;
        z-index: -1;
    }

    &.white {

        &::after {
            background: $color-first
        }
    }
}
.dropDown{
    background: $white-color;
    border-radius: 20px;
    position: absolute;
    top: 110%;
    left: 0;
    
    width:100%;
    box-shadow: $box-shadow-select;
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: all .3s;
    padding:.5rem 0;
    &__item{
    font-size: 1.4rem;
        color:$color-typo-second !important;
        padding:.5rem 2rem .8rem;
        width: 100%;
        display:inline-block;
        cursor: pointer;
    transition: all .3s;
        
        &:hover{
            background: $color-first;
            color:$white-color !important;
        }
    }
    &.show{
        visibility: visible;
        opacity: 1;
        transform: none;
    }
}
.scroll-btn {
    width: 1.5rem;
    height: 2.2rem;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid $white-color;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.4rem;

    span {
        width: .7rem;
        height: .7rem;
        display: inline-block;
        background: $white-color;
        border-radius: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        animation: bounce 1s .6s infinite;

        bottom: .4rem;
        position: absolute;


    }

    display: none;

    @include respond(m) {
        display: inline-block;
    }

}

.force-font{
    * {
        font-family: 'SF-Regular' !important;
    }
}
.force-font-size{
    * {
        font-size: 1.6rem !important;
       line-height: 1.9 !important;
    }
}
.show-all {

    a {
        font-size: 2.4rem;
        line-height: 31px;
        cursor: pointer;
        // color: $white-color;
    }

    img {
        width: 1.2rem;
        height: 1.8rem;
        margin-right: .8rem;
    }

    &.panel-related {
        position: absolute;
        top: -8.2rem;
        left: 2rem;

        z-index: 0;


        display: flex;
        justify-content: flex-end;
    }
}

.slide-wrapper {
    &:focus {
        outline: none;
    }
}

.pd-top {
    padding-top: 1rem;

    &-big {
        padding-top: 2rem;

    }

    &-bigger {
        padding-top: 2.6rem;

    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    &.reverse{
        flex-direction: row-reverse;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.wrap_center {
        flex-wrap: wrap;
        justify-content: center;

        .text-right {
            margin-bottom: 1.8rem;
        }

        div {
            width: 100%;
            text-align: center;
        }

        @include respond(s-x) {


            justify-content: center;

        }

        @include respond(m) {
            div {
                width: auto;

            }

            justify-content: space-between;

            .text-right {
                margin-bottom: 0rem;
            }
        }
    }

    &.center {
        justify-content: center;

    }
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

// .justify-center{
//     justify-content: center;
// }
.title-line {

    width: 50%;
    //  height:2px;
    margin: .8rem auto;

    display: flex;
    align-items: center;
    justify-content: center;

    div {
        background-color: #fff;
        height: 2px;
        transition: all .3s;
        border-radius: $border-radius;

    }

    &.big {
        margin: 1.3rem auto;

        div {
            height: 4px;

        }
    }

    .one {
        width: 54%;
    }

    .two {
        width: 12%;
        margin: 0 .3rem;
    }

    .three {
        width: 35%;
    }
}

.text-center {
    text-align: center;
}

.dots {
    display: flex;
    align-items: center;

    .dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $white-color;
        opacity: .85;

        &.active {
            border: 3px solid $white-color;
            background: $color-first;
            opacity: 1;
            height: 12px;
            width: 12px;
            margin: 0 1.2rem;
        }
    }

    &.loading {

        margin-bottom: 3.8rem;
        margin-top: 2rem;
        justify-content: center;

        .dot {
            opacity: 1;
            background: rgba($color: $color-first, $alpha: .5);
            transition: all .2s;

            &.active {
                background: rgba($color: $color-first, $alpha: .5);

                height: 8px;
                width: 8px;
                border: none;
                margin: 0 1.2rem;
            }

            &._1 {
                animation: loading .6s 0s infinite;
            }

            &._2 {
                animation: loading .6s 0.2s infinite
            }

            &._3 {
                animation: loading .6s .4s infinite;
            }

        }

        &.white {
            .dot {

                background: rgba($color: $white-color, $alpha: .5);

                &.active {
                    background: rgba($color: $white-color, $alpha: .5);

                }

                &._1 {
                    animation: loading-white .6s 0s infinite;
                }

                &._2 {
                    animation: loading-white .6s 0.2s infinite
                }

                &._3 {
                    animation: loading-white .6s .4s infinite;
                }
            }
        }
        &.smMargin{
        margin-top: 1rem;
        margin-bottom: 1rem;

        }
    }
}

.hidden-mobile {
    display: none;

    @include respond(m) {
        display: block;
    }

    &.flex {
        @include respond(m) {
            display: block;
        }
    }
}

.hidden-desktop {

    @include respond(m) {
        display: none;

    }
}

.pos-rel {
    position: relative;
}
.codeAfterSuccess{
    color:$color-first;
    direction: ltr;
}
.error-text {
    color: $color-error;
    font-size: 1.2rem;
    line-height: 26px;
    margin-bottom: 1.4rem;
}

.no-margin {
    margin: 0 !important;
}

.w-100 {
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: .2);
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    transition: all .2s;
    transition-delay: .4s;

    &.show {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;

    }
}

hr {
    background: rgba($color: $color-icons, $alpha: .4);
    width: 100%;
    height: 1px;
}

.pd-0 {
    padding: 0;
}

.breadcrumb{
    margin-bottom: 2rem;
}




/**overrides for third part libs*/

//react select
.filter-select,
.form-select {
    .select {
        &__placeholder {
            font-size: 1.2rem;
            color: $color-icons;
            font-weight: 100;
            margin: 0;
        }

        &__control {
            border: none;
            border-radius: $border-radius;
            box-shadow: $box-shadow-select;
            height: 5rem;

            &:active,
            &:focus {
                outline: 0;
            }
        }

        &__menu {
            border: none;
            border-radius: $border-radius;
            box-shadow: $box-shadow-select;
            font-weight: 100;
        }

        &__option {
            font-size: 1.4rem;


        }

        &__value-container {
            padding-top: 1rem;
            padding-bottom: 1rem;

        }

        &__indicator-separator {
            display: none;
        }

        &__single-value {
            padding-top: 1rem;
            font-size: 1.4rem;
            font-family: 'SF-Medium';
            padding-bottom: 1rem;
            color: $color-typo-main;

        }

        &__indicator {
            padding: 1.6rem;

            img {
                width: 1.2rem;
                height: 1.4rem;
            }
        }
    }
}

.form-select {
    .select {

        &__control {
            background: $color-input;

            // padding: 0 .5rem 0;
            box-shadow: none;
            border: 1px solid transparent;

            &:active,
            &:hover,
            &:focus {
                border: 1px solid $color-first;
            }
        }

        &__menu {
            left: 0;
            width: 9.6rem;

            //height: 17.1rem;
            &-list {
                width: 9.6rem;
                height: 17.1rem;

            }

        }

        &__withImg {
            display: flex;
            align-items: center;

            img {
                width: 2.3rem;
                //before edit
                // width: 2.8rem;
                // height: 1.6rem;
                margin-left: 1.4rem;
            }

            span {
                color: $color-typo-second;
                font-size: 1.4rem;
            }

        }

        &__option {
            padding: .5rem 1.1rem .8rem .5rem;

            &:hover {
                span {

                    color: $white-color;
                }
            }

            &--is-selected {
                span {

                    color: $white-color;
                }
            }
        }

        &__single-value {
            img {
                width:2.4rem;
                //beforre edit
                // width: 28px;
                // height: 16px;
            }

            span {
                @include respond(m) {
                    display: none;

                }
            }
        }

        &__indicator {
            padding: .8rem;

        }
    }

    &.small {
        .select {
            &__menu {

                //margin-left: 17px;
                width: 11.2rem;

                &-list {
                    height: 12.1rem;
                    width: 11.2rem;

                    @include respond(s) {
                        width: 11.2rem;

                    }
                }

            }
        }
    }

    &.fixed {
        .select {
            &__menu {

                //margin-left: 17px;
                position: fixed;
                top: 55%;
                left: 2rem;
            }

        }

        &.closer {
            .select {
                &__menu {

                    top: 52%;

                }


            }
        }
    }

}

.in-form {
    &.has-icon {
        .select {
            &__control {
                padding-right: 4rem;
            }
        }
    }

    .select {
        &__placeholder {
            font-size: 1.4rem;
            color: $color-typo-third;
            font-weight: 100;
        }

        &__control {
            background: $color-input;

            box-shadow: none;
            padding-right: 1.6rem;
            padding-left: 1.6rem;

        }

        &__value-container {
            padding-right: 0;
            padding-left: 0;
        }

        &__indicator {
            padding-left: 0;
        }

        &__single-value {

            font-family: 'SF-Regular';


        }

        &__indicator {
            img {
                width: 1.4rem;
                height: 1.6rem;
            }
        }
    }
}



//react modal

.ReactModal__Overlay {
    opacity: 0;
    //transform: translateX(-100px);
    transition: all 100ms ease-in-out;
    z-index: 2000;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    //transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    //transform: translateX(-100px);
}

.ReactModal__Content {
    opacity: 0;
    transform: translate(-50%, -200px);
    transition: all 300ms ease-in-out;
    z-index: 2000;
}

.ReactModal__Content--after-open {
    opacity: 1;
    transform: translate(-50%, 0px);
}

.ReactModal__Content--before-close {
    opacity: 0;
    transform: translate(-50%, -200px);
}


.ReactModal__Content {

    box-shadow: $box-shadow-select;

    @include respond(m) {
        top: 16% !important;
        //max-height: 77% !important;
    }
}



//date picker
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: $color-input;

    border-radius: $border-radius-sm;
}

.rdtPicker {
    box-shadow: $box-shadow-select;
    border-radius: $border-radius;
    margin-top: .4rem;

    th,
    td {
        color: $color-typo-main;

        &.rdtOld,
        &.rdtNew {
            color: $color-typo-third;

        }
    }

    td {
        &.rdtActive {
            background: $color-first;
            border-radius: $border-radius-sm;
            display: flex;


            align-items: center;
            justify-content: center;

            &:hover {
                background: $color-first;
                border-radius: $border-radius-sm;
            }
        }

    }

    thead {
        padding-bottom: 1rem;
    }
}

.rdtPicker thead tr:first-of-type th:hover {
    display: flex;


    align-items: center;
    justify-content: center;
    border-radius: $border-radius-sm;
    background: $color-input;
}



//slick slider
.slick-dots li.slick-active button:before {
    border: 3px solid $white-color;
    background: $color-first;
    opacity: 1;
    height: 12px;
    width: 12px;
    // margin: 0 1.2rem;
    border-radius: 50%;
}

.slick-dots li {
    width: .8rem;
    height: .8rem;
    margin: 0 .6rem;
    vertical-align: top;
}

.slick-dots li button:before {
    height: 8px;
    width: 8px;
    font-size: 0;
    border-radius: 50%;
    background: $white-color;
    opacity: .85;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.custom-dots {
    li {
        margin: 0 .7rem;
        vertical-align: top;
    }

    .custom-dot {
        background: rgba($color: $white-color, $alpha: .5);

        width: 1.7rem;
        height: 1.7rem;
        border-radius: 50%;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            position: relative;
            top: 0;
            left: 0;
            display: inline-block;

            border-radius: 50%;

            background: $white-color;
            transform: scale(0);
            width: .9rem;
            height: .9rem;

            transition: transform .3s;
        }
    }

    .slick-active .custom-dot {
        &::after {
            transform: scale(1);

        }
    }
}



// english language 'ltr' layout changes
.english {
    
.flex-center {
   
    &.reverse{
        flex-direction: row;
    }
}

    .show-all {
        img {
            transform: rotate(180deg);
            margin-left: .8rem;
            margin-right: 0rem;
        }

        &.panel-related {

            left: auto;

            right: 2rem;


        }
    }

    .form-select {
        .select {
            &__menu {
                left: auto;
                right: 0;
            }
        }

        &.fixed,
        &.closer {
            .select {
                &__menu {
                    left: auto;
                    right: 2rem;
                }
            }
        }
    }

    .form-select .select__withImg img {
        margin-left: 0;
        margin-right: 1.4rem;
    }

    .text-right {
        text-align: left;
    }

    .text-left {
        text-align: right;
    }

    .in-form.has-icon .select__control {
        padding-right: 1.6rem;
        padding-left: 4rem;
    }

    .in-form .select__indicator {
        padding-left: 1.6rem;
        padding-right: 0;
    }
}