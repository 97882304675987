@mixin clearfix {
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

@mixin coverImage {
    @supports (object-fit: cover) {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

        }
      }

      @supports not (object-fit: cover) {
        img {
          position: absolute;
          left: -10000%;
          right: -10000%;
          top: -10000%;
          bottom: -10000%;
          margin: auto auto;
          min-width: 1000%;
          min-height: 1000%;
          -webkit-transform: scale(0.1);
          transform: scale(0.1);

        }
      }
}
// 530 min Small
// 830 min Medium
// 1200 min Large
@mixin atSmall {
    @media (min-width: 530) {
        @content
    };
}
@mixin respond($breakpoint){
    @if $breakpoint == xxxs{
        @media (min-width:  21.8125em) { 
            @content
        };
    }
    @if $breakpoint == xxs{
        @media (min-width:  23.8125em) { //500
            @content
        };
    }
    @if $breakpoint == xxs2{
        @media (min-width:  25.8125em) { //500
            @content
        };
    }
    @if $breakpoint == xs{
        @media (min-width: 31.25em) { //500
            @content
        };
    }
    @if $breakpoint == s{
        @media (min-width: 48em) { //768
            @content
        };
    }
    @if $breakpoint ==s-x{
        @media (min-width: 53em) { //845
            @content
        };
    }
    @if $breakpoint == m{
        @media (min-width: 63.5em) { //1016
            @content
        };
    }
    @if $breakpoint == l{
        @media (min-width: 68.75em) { //1100
            @content
        };
    }
    @if $breakpoint == l2{
        @media (min-width: 1600px) { //1800
            @content
        };
    }
    @if $breakpoint == xl{
        @media (min-width: 112.5em) { //1800
            @content
        };
    }
    @if $breakpoint == xxl{
        @media (min-width: 150.5em) { //1800
            @content
        };
    }
    @if $breakpoint == h{
        @media (min-height: 670px) { //400
            @content
        };
    }
    @if $breakpoint == h2{
        @media (min-height: 800) { //400
            @content
        };
    }

}

@mixin atMedium {
    @media (min-width: 830) {
        @content
    };
}
@mixin atLarge {
    @media (min-width: 1200) {
        @content
    };
}