

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 100;
}
input:required {
    box-shadow: none;
}
html {

    // make the base font size as 10px in order to use 'rem' as our measure, so that that 1rem ===10px
     font-size: 10px; 
     
  
}


@font-face {
    font-family: 'SF-Bold';
    font-style: normal;

    src: local("SF-Bold"), url('./../../fonts/STC Forward Bold.ttf') format('truetype');
    /* IE9 Compat Modes */
  }
  @font-face {
    font-family: 'SF-Regular';
    font-style: normal;

    src: local("SF-Regular"), url('./../../fonts/STC Forward Regular.ttf') format('truetype');
    /* IE9 Compat Modes */
  }
  @font-face {
    font-family: 'SF-Medium';
    font-style: normal;

    src: local("SF-Medium"), url('./../../fonts/STC Forward Medium.ttf') format('truetype');
    /* IE9 Compat Modes */
  }



body {
    direction: rtl;
    box-sizing: border-box;
    padding: 0;
    font-family: 'SF-Regular', sans-serif;
    background-color: $body-bg;
    font-size: $default-font-size;
    min-height: 100vh;
    font-weight: 100;
    color: $color-typo-main;
    overflow-x: hidden;
    &.english{
        direction: ltr;
    }
}
button {
    font-family : inherit;
}
img{
    width: 100%;
    height: auto;
}
h1,h2,h3,h4,h5,h6{
    font-size: 1.6rem;
}
hr{
    // margin-top: 1rem;
    // margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1); height: 0;
    overflow: visible;

}
p{
    font-family: 'SF-Regular', sans-serif;
    font-size: $default-font-size;


}
.h1{
    font-family: 'SF-Bold', sans-serif;
    font-weight: 100;
}
.h2{
    font-size: 2.2rem;


}
ul{
    list-style: none;
}
input {
    font-family: 'SF-Regular';
}
*,
input,
textarea,
button,
select,
a,
li {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}