/*Animation : V.008*/
@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(-1rem);
        /*if the value is positive means fo to right*/
    }

    100% {
        opacity: 1;
        transform: translateX();
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
        /*if the value is positive means fo to right*/
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }

}

@keyframes scaleUp {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

@keyframes loading {

    50% {
        background: rgba($color: $color-first, $alpha: 1);

    }

    100% {
        background: rgba($color: $color-first, $alpha: .5);

    }
}
@keyframes loading-white {

    50% {
        background: rgba($color: $white-color, $alpha: 1);

    }

    100% {
        background: rgba($color: $white-color, $alpha: .5);

    }
}


@keyframes bounce {

    50% {
        transform: translate(-50%, -5px);
        opacity: .15;
    }

    75% {

        opacity: .5;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;

    }
}